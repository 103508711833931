import * as React from 'react';
import {useEffect} from 'react';
import {
  B2CComponentsProvider,
  Body,
  LoadingAnimationWrapper,
  SSOPage,
} from '@edekadigital/b2c-components';

import {BrandPanel, Footer, Header} from '../../components';
import {useRequestOneTimeCode} from '../../common/hooks/useRequestPin';
import {
  LOGIN_PIN_MESSAGE,
  PIN_REQUEST_ERROR_CODE_NOT_AVAILABLE,
} from '../../common/constants';
import EdekaTitle from '../../components/commons/Title';
import {NoSsr} from '@mui/material';
import sleep from '../../common/utils/sleep';

export const Head = EdekaTitle;
const RequestPin: React.FC = () => {
  const [loginPin, setLoginPin] = React.useState<string>();
  const [message, setMessage] = React.useState<string>();
  const [showContent, setShowContent] = React.useState<boolean>(false);
  const [fetchingOneTimeCodeComplete, setFetchingOneTimeCodeComplete] =
    React.useState<boolean>(false);
  const {fetchOneTimeCode} = useRequestOneTimeCode();

  const header = <Header />;
  const footer = <Footer />;
  const adornment = <BrandPanel />;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const customerId = urlSearchParams.get('customerId') ?? undefined;
    const requestCodeId = urlSearchParams.get('requestCodeId') ?? undefined;

    if (customerId && requestCodeId) {
      fetchOneTimeCode(customerId, requestCodeId).then(response => {
        if (response?.loginCode) {
          setLoginPin(response?.loginCode);
          sleep(5000).then(() => {
            setShowContent(true);
          });
        } else {
          setMessage(PIN_REQUEST_ERROR_CODE_NOT_AVAILABLE);
        }
        setFetchingOneTimeCodeComplete(true);
      });
    } else {
      setFetchingOneTimeCodeComplete(true);
    }
  }, []);

  if (!fetchingOneTimeCodeComplete)
    return (
      <B2CComponentsProvider>
        <LoadingAnimationWrapper isLoading={true} />
      </B2CComponentsProvider>
    );

  const content = loginPin ? (
    <>{showContent && LOGIN_PIN_MESSAGE(loginPin)}</>
  ) : (
    <>
      <Body gutterBottom={true}>{message}</Body>
    </>
  );

  return (
    <B2CComponentsProvider>
      <SSOPage header={header} footer={footer} adornment={adornment}>
        <NoSsr>{content}</NoSsr>
      </SSOPage>
    </B2CComponentsProvider>
  );
};

export default RequestPin;
