const sleep = async (sleepInMillis: number): Promise<void> => {
  if (process.env.NODE_ENV === 'test') {
    // ignore timouts in jest tests for faster execution and easier testing
    return;
  }
  if (sleepInMillis < 0) {
    return;
  }
  await new Promise(resolve => setTimeout(resolve, sleepInMillis));
};

export default sleep;
