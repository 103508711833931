import {requestOneTimeCode, OneTimeCodeResponse} from '../services';
import sleep from '../utils/sleep';
import {AxiosError} from 'axios';

export interface OneTimeCodeModel {
  loginCode?: string;
}

export const useRequestOneTimeCode = () => {
  const fetchOneTimeCode = async (
    customerId: string,
    requestCodeId: string
  ): Promise<OneTimeCodeModel | null> => {
    // only for local development set TESTID for both parameters
    if (customerId === 'TESTID' && requestCodeId === 'TESTID') {
      /* istanbul ignore next */
      await sleep(1000);
      return {loginCode: String(Math.random())};
    }

    try {
      const response = await requestOneTimeCode(customerId, requestCodeId);
      if (response.status === 201 || response.status === 200)
        return {loginCode: response.data.loginCode};
    } catch (e) {
      // keep it simple, all not known response codes are unexpected
    }
    return null;
  };
  return {fetchOneTimeCode};
};
